import { Experiments } from '@wix/yoshi-flow-editor';
import { ISettingsContextValue } from '@wix/yoshi-flow-editor/tpa-settings';
import { ExperimentsConsts } from '../../consts/experiments';

type GetContentParams = {
  settings: Partial<ISettingsContextValue>;
  settingsParam: any;
  experiments?: Experiments;
};

export const getContent = ({
  settings,
  settingsParam,
  experiments,
}: GetContentParams) => {
  const isTrimSettingParamsBookingsFormEnabled = experiments?.enabled(
    ExperimentsConsts.TrimSettingParamsBookingsForm,
  );
  return (
    (isTrimSettingParamsBookingsFormEnabled
      ? settings.get?.(settingsParam)
      : settings.get?.(settingsParam)?.trim()) ||
    settings.getDefaultValue?.(settingsParam)
  );
};
